<template>
<div>
    <div v-for="(item, index) in medalItemList" :key="item.id">
        <v-row class="mt-5">
            <v-col cols="12" sm="4">
                <v-select
                    v-model="item.medalTypeId"
                    outlined                                               
                    :label="$t('select.type')"
                    item-value="id"
                    :items="medalTypeData"
                    :rules="rule.requiredField()"
                    @change="selectMedalType(item,index)"
                    hide-details
                    dense
                >
                    <template slot="selection" slot-scope="data">
                        <span v-if="$t('lang') == 'KH'">
                        {{data.item.medalTypeKh}}
                        </span>
                        <span v-else>{{data.item.medalTypeEn}}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                        <span v-if="$t('lang') == 'KH'">
                        {{data.item.medalTypeKh}}
                        </span>
                        <span v-else>{{data.item.medalTypeEn}}</span>
                    </template>
                 </v-select>
            </v-col>
            <v-col cols="12" sm="4">
                <v-select
                    v-model="item.medalId"
                    outlined                                               
                    :label="$t('select.medal')"
                    item-value="id"
                    :items="medalData[index]"
                    hide-details
                    dense
                >
                    <template slot="selection" slot-scope="data">
                        <span v-if="$t('lang') == 'KH'">
                        {{data.item.medalTypeKh}}
                        </span>
                        <span v-else>{{data.item.medalTypeEn}}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                        <span v-if="$t('lang') == 'KH'">
                        {{data.item.medalTypeKh}}
                        </span>
                        <span v-else>{{data.item.medalTypeEn}}</span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" sm="4">
                <v-text-field 
                    outlined
                    dense
                    :label="$t('text.medalProgramTitle')"
                    v-model="item.medalProgram"
                    hide-details
                >                                         
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="3">
                <v-text-field 
                    outlined
                    dense
                    :label="$t('text.country')"
                    v-model="item.country"
                    hide-details
                >                                         
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
                <v-text-field 
                    outlined
                    dense
                    :label="$t('text.giverName')"
                    v-model="item.givenBy"
                    hide-details
                >                                         
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
                <v-text-field
                    v-model="item.medalDate"
                    :label="$t('text.receivingDate')"
                    outlined
                    dense
                    type="date"
                    hide-details
                ></v-text-field> 
            </v-col>
            <v-col cols="12" sm="3">
                <v-select
                    v-model="item.letterType"
                    outlined                                               
                    :label="$t('select.medalLetterType')"
                    item-value="{}"
                    :items="appointmentTypeList"
                    hide-details
                    dense
                >
                    <template slot="selection" slot-scope="data">
                        <span v-if="$t('lang') == 'KH'">
                        {{data.item.nameKh}}
                        </span>
                        <span v-else>{{data.item.nameEn}}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                        <span v-if="$t('lang') == 'KH'">
                        {{data.item.nameKh}}
                        </span>
                        <span v-else>{{data.item.nameEn}}</span>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="3">
                <v-text-field
                    v-model="item.letterNo"
                    :label="$t('text.letterNo')"
                    outlined
                    dense
                    hide-details
                ></v-text-field> 
            </v-col>
            <v-col cols="12" sm="3">
                <v-btn
                    color="primary"
                    @click="openFile(index)"
                    small
                    rounded
                >
                    <v-icon small>{{icons.mdiAttachment}}</v-icon>
                </v-btn>
                        
                <input type="file"
                    accept=".jpeg,.png,.jpg"                       
                    :hidden="true"
                    :id="index"
                >
                <v-icon large color="primary" @click="removeItem(index)">{{icons.mdiDelete}}</v-icon>
            </v-col>
        </v-row>
    </div>
    <v-row class="mt-5 justify-center">               
        <v-col cols="4" align="center">
            <v-btn 
                class="btn primary" 
                @click="addItem($event)"
                small
                rounded
            ><v-icon small>{{icons.mdiPlus}}</v-icon> {{$t('button.medal')}}</v-btn>
        </v-col>                
    </v-row>
</div>
</template>

<script>
import { mdiCalendarAlert, mdiPlus, mdiMinus, mdiDelete, mdiAttachment } from '@mdi/js';
import SystemService from '@/services/service.system';
import StaffDetailService from '@/services/service.staffDetail';
import Rule from '@/plugins/rules.js';

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiMinus,
        mdiDelete,
        mdiAttachment,
      },
      rule: [],
      medalItemList: [],
      medalTypeData: [],
      medalData: Array,
      appointmentTypeList: [],
    };
  },
  async created() {
    this.rule = Rule;

    SystemService.getMedalTypeByParent(0).then(response => {
      this.medalTypeData = response.data;
    });
    /*
    SystemService.getMedalTypeByNonParent().then(response => {
      this.medalData[0] = response.data;
    });
    */

    SystemService.getAppointmentTypeAll().then(response => {
      this.appointmentTypeList = response.data;
    });

    if (this.$route.params.action === 'edit') {
      this.staff = this.$route.params.data;

      await StaffDetailService.getMedalHistoryByStaffId(this.staff.id).then(response => {
        this.medalItemList = response.data;
      });

      this.medalItemList.forEach((item, index) => {
        SystemService.getMedalTypeByParent(item.medalTypeId).then(response => {
          this.medalData[index] = response.data;
          this.$forceUpdate();
        });
      });
    }
  },
  watch: {
    medalItemList: function(val) {
      this.$emit('updateMedalList', val);
    },
  },
  methods: {
    addItem() {
      this.medalItemList.push({});
    },
    async selectMedalType(item, index) {
      //  alert(JSON.stringify(item.medalTypeId));
      await SystemService.getMedalTypeByParent(item.medalTypeId).then(response => {
        this.medalData[index] = response.data;
      });
      this.$forceUpdate();
      //   alert(JSON.stringify(this.medalData[index]));
    },
    removeItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            this.medalItemList.splice(index, 1);
          }
        },
      });
    },
    openFile(index) {
      document.getElementById(index).click();
    },
  },
};
</script>
