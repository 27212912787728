<template>
    <div>
        <div v-for="(item, index) in workItemList" :key="item.id">
            <v-row class="mt-5">
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="item.officeName"
                        :rules="rule.requiredField()"
                        outlined
                        hide-details
                        :label="$t('text.officeName')"
                        dense
                    >

                    </v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                    <v-text-field
                        v-model="item.officeAddress"
                        outlined
                        hide-details
                        :label="$t('text.address')"
                        dense
                    >

                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="item.country"
                        outlined
                        hide-details
                        :label="$t('text.country')"
                        dense
                    >

                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="item.position"
                        outlined
                        hide-details
                        :label="$t('text.role')"
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="item.appointmentNo"
                        outlined
                        hide-details
                        :label="$t('text.idNumber')"
                        dense
                    >

                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        v-model="item.appointmentType"
                        outlined                                               
                        :label="$t('select.appointmentType')"
                        :items="appointmentTypeList"
                        item-value="{}"
                        hide-details
                        dense
                    >
                        <template slot="selection" slot-scope="data">
                            <span v-if="$t('lang') == 'KH'">
                            {{data.item.nameKh}}
                            </span>
                            <span v-else>{{data.item.nameEn}}</span>
                        </template>
                        <template slot="item" slot-scope="data">
                            <span v-if="$t('lang') == 'KH'">
                            {{data.item.nameKh}}
                            </span>
                            <span v-else>{{data.item.nameEn}}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="6" sm="3" class="text-center">
                    <v-btn
                        color="primary"
                        @click="openFile(index)"
                        small
                        rounded
                        class="me-5"
                    >
                        <v-icon small>{{icons.mdiAttachment}}</v-icon>
                    </v-btn>
                        
                    <input type="file"
                        accept=".jpeg,.png,.jpg"                       
                        :hidden="true"
                        :id="index"
                    >
                    <v-hover v-slot="{ hover }">
                    <v-btn
                        @click="openMedalForm(index)"
                        small
                        rounded
                        :color="hover?'secondary':'warning'"                       
                    >
                        <v-icon small>{{icons.mdiMedal}}</v-icon>
                    </v-btn>
                    </v-hover>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="2">
                    <v-text-field
                        v-model="item.startDate"
                        :label="$t('text.startDate')"
                        outlined
                        dense
                        type="date"
                        hint="month / day / year"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                        v-model="item.endDate"
                        :label="$t('text.endDate')"
                        outlined
                        dense
                        type="date"
                        hint="month / day / year"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1">
                    <v-icon large color="primary" @click="removeItem(index)">{{icons.mdiInboxRemove}}</v-icon>
                </v-col>             
            </v-row>
        </div>
        <v-dialog 
            v-model="medalDialog"
            width="800"
        >
            <v-card>
                <v-card-title><span class="primary--text">{{$t('caption.medalForm')}}</span></v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="medal.medalName"
                                :label="$t('text.medalTitle')"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="medal.programTitle"
                                :label="$t('text.medalProgramTitle')"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="medal.country"
                                :label="$t('text.country')"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="medal.giverName"
                                :label="$t('text.giverName')"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="medal.receivingDate"
                                :label="$t('text.receivingDate')"
                                outlined
                                dense
                                type="date"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                        rounded
                        color="primary"
                        @click="saveMedal"
                    >
                        <v-icon small>{{icons.mdiMedal}}</v-icon> Save
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-row class="mt-5 justify-center">               
            <v-col cols="4" align="center">
                <v-btn 
                    class="btn primary" 
                    @click="addItem($event)"
                    small
                    rounded
                ><v-icon small>{{icons.mdiPlus}}</v-icon></v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mdiCalendarAlert, mdiPlus, mdiInboxRemove, mdiAttachment, mdiDelete, mdiMedal } from '@mdi/js';
import SystemService from '@/services/service.system';
import StaffDetailService from '@/services/service.staffDetail';
import Rule from '@/plugins/rules.js';

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiInboxRemove,
        mdiAttachment,
        mdiDelete,
        mdiMedal,
      },
      rule: [],
      positionList: [],
      appointmentTypeList: [],
      workItemList: [],
      staff: {},
      medal: {},
      medalDialog: false,
    };
  },
  created() {
    if (this.$route.params.action === 'edit') {
      this.staff = this.$route.params.data;

      StaffDetailService.getWorkByStaffId(this.staff.id).then(response => {
        this.workItemList = response.data;
      });
    }
    this.rule = Rule;

    SystemService.getPositionAll().then(response => {
      this.positionList = response.data;
    });

    SystemService.getAppointmentTypeAll().then(response => {
      this.appointmentTypeList = response.data;
    });
  },
  watch: {
    workItemList: function(val) {
      this.$emit('updateWorkList', val);
    },
  },
  methods: {
    addItem() {
      this.workItemList.push({ appointmentType: {} });
    },
    removeItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            this.workItemList.splice(index, 1);
          }
        },
      });
    },
    openFile(index) {
      document.getElementById(index).click();
    },
    openMedalForm(index) {
      // alert(JSON.stringify(index));
      this.medal.index = index;
      this.medalDialog = true;
    },
    saveMedal() {
      this.workItemList[this.medal.index].medal = this.medal;
      alert(JSON.stringify(this.workItemList));
      this.medal = {};
      this.medalDialog = false;
    },
  },
};
</script>