<template>
<div>
    <v-card-title><span class="primary--text">{{$t('caption.training')}} </span></v-card-title>
    <div v-for="(item, index) in trainingItemList" :key="item.id">
    <v-row>
        <v-col cols="12" md="5">
            <v-text-field
                v-model="item.venue"
                outlined
                hide-details
                :label="$t('text.trainingVenue')"
                dense
                :rules="rule.requiredField()"
            >

            </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
            <v-text-field
                v-model="item.trainingCity"
                outlined
                hide-details
                :label="$t('text.provinceOrState')"
                dense
            >

            </v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field
                v-model="item.trainingCountry"
                outlined
                hide-details
                :label="$t('text.country')"
                dense
            >

            </v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="4">
            <v-select
                v-model="item.trainingType.id"
                outlined                                               
                :label="$t('select.trainingType')"
                :items="trainingTypeList"
                item-value="id"
                :rules="rule.requiredField()"
                hide-details
                dense
            >
                <template slot="selection" slot-scope="data">
                    <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                    </span>
                    <span v-else>{{data.item.nameEn}}</span>
                </template>
                <template slot="item" slot-scope="data">
                    <span v-if="$t('lang') == 'KH'">
                        {{data.item.nameKh}}
                    </span>
                    <span v-else>{{data.item.nameEn}}</span>
                </template>
            </v-select>
        </v-col>
        <v-col cols="12" md="4">
            <v-text-field
                v-model="item.title"
                outlined
                hide-details
                :label="$t('text.trainingProgramTitle')"
                dense
            >

            </v-text-field>
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                v-model="item.startDate"
                :label="$t('text.startDate')"
                outlined
                dense
                type="date"
                hint="month / day / year"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                v-model="item.endDate"
                :label="$t('text.endDate')"
                outlined
                dense
                type="date"
                hint="month / day / year"
               
            ></v-text-field>       
            <v-btn
                color="primary"
                @click="openFile(index)"
                small
                rounded
            >
                <v-icon small="">{{icons.mdiAttachment}}</v-icon>
            </v-btn>
               
            <input type="file"
                accept=".jpeg,.png,.jpg"                       
                :hidden="true"
                :id="index"
            >
            <v-icon class="mr-5" large color="primary" @click="removeItem(index)">{{icons.mdiDelete}}</v-icon>
        </v-col>
    </v-row>
    <!--
    <v-row class="justify-end">
        <v-spacer></v-spacer>
        <v-col cols="4" class="text-right">
            <v-btn 
                class="btn primary" 
                v-if="trainingItemList.length === index+1"
                @click="addItem($event)"
                small
            ><v-icon small>{{icons.mdiPlus}}</v-icon></v-btn>
        </v-col>
    </v-row>
    -->
    </div>
    <v-row class="mt-5 justify-center">               
        <v-col cols="4" align="center">
            <v-btn 
                class="btn primary" 
                @click="addItem($event)"
                small
                rounded
            ><v-icon small>{{icons.mdiPlus}}</v-icon></v-btn>
        </v-col>
    </v-row>
</div>
</template>

<script>
import { mdiCalendarAlert, mdiPlus, mdiAttachment, mdiDelete } from '@mdi/js';
import SystemService from '@/services/service.system';
import StaffDetailService from '@/services/service.staffDetail';
import Rule from '@/plugins/rules.js';

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiAttachment,
        mdiDelete,
      },
      rule: [],
      trainingTypeList: [],
      trainingItemList: [],
    };
  },
  created() {
    this.rule = Rule;

    if (this.$route.params.action === 'edit') {
      this.staff = this.$route.params.data;

      StaffDetailService.getTrainingByStaffId(this.staff.id).then(response => {
        this.trainingItemList = response.data;
      });
    }

    SystemService.getTrainingTypeAll().then(response => {
      this.trainingTypeList = response.data;
    });
  },
  watch: {
    trainingItemList: function(val) {
      // alert(JSON.stringify(val))
      this.$emit('updateTrainingList', val);
    },
  },
  methods: {
    addItem() {
      this.trainingItemList.push({ trainingType: {} });
    },
    removeItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            this.trainingItemList.splice(index, 1);
          }
        },
      });
    },
    openFile(index) {
      document.getElementById(index).click();
    },
  },
};
</script>
