<template>
    <div class="mt-5">
        <v-form ref="form" lazy-validation>
        <div v-for="(item, index) in relativeItemList" :key="item.id">
            <v-row class="mt-5">
                <v-col cols="12" sm="3">
                    <input type="hidden" v-model="item.relation.id" />
                    <v-text-field
                        v-model="item.nameKh"
                        :label="$t('text.name') + ' ' + item.relation.nameKh"
                        outlined
                        hide-details
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field
                        v-model="item.nameEn"
                        :label="$t('text.nameLatin')"
                        outlined
                        hide-details
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" v-if="item.relation.nameEn == 'Child'" class="d-flex justify-center flex-wrap">
                    <v-radio-group row v-model="item.gender" hide-details>
                        <v-radio :label="$t('text.male')" :value=0></v-radio>
                        <v-radio :label="$t('text.female')" :value=1></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                        v-model="item.dob"
                        :label="$t('text.dateOfBirth')"
                        outlined
                        dense
                        type="date"
                        hint="month / day / year"
                        hide-details
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" md="2" v-if="item.relation.nameEn == 'Father' || item.relation.nameEn == 'Mother'">
                    <v-radio-group row v-model="item.isAlive" class="mt-n1" hide-details @change="closeAddress(index, item.isAlive)">
                        <v-radio :label="$t('text.alive')" :value="true"></v-radio>
                        <v-radio :label="$t('text.dead')" :value="false"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" v-if="item.relation.nameEn == 'Father' || item.relation.nameEn == 'Mother'" :id="index">
                    <v-text-field
                        v-model="item.address"
                        :label="$t('text.address')"
                        outlined
                        hide-details
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" :id="'occupation'+index">
                    <v-text-field
                        v-model="item.occupation"
                        :label="$t('text.occupation')"
                        outlined
                        hide-details
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" v-if="item.relation.nameEn == 'Spouse'">
                    <v-btn
                        color="primary"
                        @click="openFile(index)"
                        small
                        rounded
                    >
                        <v-icon small>{{icons.mdiAttachment}}</v-icon>
                    </v-btn>
                    ភ្ជាប់សំបុត្រអាពាហ៍ពិពាហ៍
                    <input type="file"
                        accept=".jpeg,.png,.jpg"                       
                        @change="onFileChange($event)"
                        :hidden="true"
                        :id="index"
                    >
                </v-col>
                <v-col cols="12" sm="3" v-if="item.relation.nameEn == 'Child'">
                    <v-btn
                        color="primary"
                        @click="openFile(index)"
                        small
                        rounded
                    >
                        <v-icon small>{{icons.mdiAttachment}}</v-icon>
                    </v-btn>
                    ភ្ជាប់សំបុត្រកំណើតកូន
                    <input type="file"
                        accept=".jpeg,.png,.jpg"                       
                        @change="onFileChange($event)"
                        :hidden="true"
                        :id="index"
                    >
                </v-col>
            </v-row>
        </div>
        <v-row class="mt-5 justify-center">               
            <v-col cols="4" align="center">
                <v-btn 
                    class="btn primary" 
                    @click="addItem($event)"
                    small
                    rounded
                ><v-icon small>{{icons.mdiPlus}}</v-icon> {{$t('button.addChild')}}</v-btn>
            </v-col>
        </v-row>
        </v-form>
    </div>
</template>

<script>
import { mdiCalendarAlert, mdiPlus, mdiAttachment } from '@mdi/js';
import SystemService from '@/services/service.system';
import StaffDetailService from '@/services/service.staffDetail';
import Rule from '@/plugins/rules.js';

export default {
  props: {
    data: Array,
  },
  name: 'add-relatives',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiAttachment,
      },
      rule: [],
      relationList: [],
      relativeItemList: [],
      spouseRelation: '',
      fatherRelation: '',
      motherRelation: '',
      childRelation: '',
      showAddress: true,
    };
  },
  created() {
    this.rule = Rule;

    SystemService.getRelationAll().then(response => {
      this.relationList = response.data;
      this.relationList.forEach(element => {
        switch (element.nameEn) {
          case 'Spouse':
            this.spouseRelation = element;
            break;
          case 'Father':
            this.fatherRelation = element;
            break;
          case 'Mother':
            this.motherRelation = element;
            break;
          case 'Child':
            this.childRelation = element;
            break;
        }
      });

      if (this.$route.params.action === 'edit') {
        this.staff = this.$route.params.data;

        StaffDetailService.getRelativesByStaffId(this.staff.id).then(response => {
          if (response.data.length > 0) {
            this.relativeItemList = response.data;
          } else {
            /*
            this.relativeItemList.push({ relation: this.spouseRelation });
            this.relativeItemList.push({ relation: this.fatherRelation });
            this.relativeItemList.push({ relation: this.motherRelation });
            */
            this.initializeRelativeList();
          }
        });
      } else {
        this.initializeRelativeList();
        /*
        this.relativeItemList.push({ relation: this.spouseRelation });
        this.relativeItemList.push({ relation: this.fatherRelation });
        this.relativeItemList.push({ relation: this.motherRelation });
        */
        //   alert(JSON.stringify(this.familyRelationList));
      }
    });
  },
  watch: {
    relativeItemList: function(val) {
      this.$emit('updateRelativesList', val);
    },
  },
  methods: {
    addItem() {
      this.relativeItemList.push({ relation: this.childRelation });
    },
    openFile(index) {
      document.getElementById(index).click();
      //  alert(JSON.stringify(this.$refs));
      //this.$refs.uploader.click();
    },
    onFileChange: function(event) {
      // alert(JSON.stringify(event.target));
      //  const file = event.target.files[0];
      //  this.formData.append('file', file);
      // this.url = URL.createObjectURL(file);
    },
    initializeRelativeList() {
      this.relativeItemList = [];
      this.relativeItemList.push({ relation: this.spouseRelation });
      this.relativeItemList.push({ relation: this.fatherRelation });
      this.relativeItemList.push({ relation: this.motherRelation });
    },
    closeAddress(index, value) {
      if (value == false) {
        document.getElementById(index).style.display = 'none';
        document.getElementById('occupation' + index).style.display = 'none';
      } else {
        document.getElementById(index).style.display = 'block';
        document.getElementById('occupation' + index).style.display = 'block';
      }
    },
  },
};
</script>

<style>
</style>
  