<template>
<div>
    <v-card-title><span class="primary--text">{{$t('caption.educationLevel')}} </span></v-card-title>
    <div v-for="(item, index) in educationItemList" :key="item.id">
        <v-row>
            <v-col cols="12" md="5">
                <v-text-field
                    v-model="item.institutionName"
                    outlined
                    hide-details
                    :label="$t('text.universityName')"
                    dense
                    :rules="rule.requiredField()"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="item.state"
                    outlined
                    hide-details
                    :label="$t('text.provinceOrState')"
                    dense
                >

                </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field
                    v-model="item.country"
                    outlined
                    hide-details
                    :label="$t('text.country')"
                    dense
                >

                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-select
                    v-model="item.degreeLevel.id"
                    outlined                                               
                    :label="$t('select.degreeLevel')"
                    item-value="id"
                    :items="degreeList"
                    :rules="rule.requiredField()"
                    hide-details
                    dense
                >
                    <template slot="selection" slot-scope="data">
                        <span v-if="$t('lang') == 'KH'">
                        {{data.item.nameKh}}
                        </span>
                        <span v-else>{{data.item.nameEn}}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                        <span v-if="$t('lang') == 'KH'">
                        {{data.item.nameKh}}
                        </span>
                        <span v-else>{{data.item.nameEn}}</span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="item.qualificationTitle"
                    outlined
                    hide-details
                    :label="$t('text.degreeTitle')"
                    dense
                >

                </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="item.startDate"
                    :label="$t('text.startDate')"
                    outlined
                    dense
                    type="date"
                    hint="month / day / year"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="item.endDate"
                    :label="$t('text.endDate')"
                    outlined
                    dense
                    type="date"
                    hint="month / day / year"
                ></v-text-field>
                <v-btn
                    color="primary"
                    @click="openFile(index)"
                    small
                    rounded
                >
                    <v-icon small>{{icons.mdiAttachment}}</v-icon>
                </v-btn>
                
                <input type="file"
                    accept=".jpeg,.png,.jpg"                       
                    :hidden="true"
                    :id="index"
                >
                <v-icon class="mr-5" large color="primary" @click="removeItem(index)">{{icons.mdiDelete}}</v-icon>
            </v-col>         
        </v-row>
    </div>
    <v-row class="mt-5 justify-center">               
        <v-col cols="4" align="center">
            <v-btn 
                class="btn primary" 
                @click="addItem($event)"
                small
                rounded
            ><v-icon small>{{icons.mdiPlus}}</v-icon></v-btn>
        </v-col>
    </v-row>
</div>
</template>

<script>
import { mdiCalendarAlert, mdiPlus, mdiAttachment, mdiDelete } from '@mdi/js';
import SystemService from '@/services/service.system';
import StaffDetailService from '@/services/service.staffDetail';
import Rule from '@/plugins/rules.js';

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiAttachment,
        mdiDelete,
      },
      rule: [],
      degreeList: [],
      educationItemList: [],
    };
  },
  created() {
    this.rule = Rule;
    if (this.$route.params.action === 'edit') {
      this.staff = this.$route.params.data;

      StaffDetailService.getEducationByStaffId(this.staff.id).then(response => {
        this.educationItemList = response.data;
      });
    }

    SystemService.getDegreeLevelAll().then(response => {
      this.degreeList = response.data;
    });
  },
  watch: {
    educationItemList: function(val) {
      this.$emit('updateEducationList', val);
    },
  },
  methods: {
    addItem() {
      this.educationItemList.push({ degreeLevel: {} });
    },
    removeItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            this.educationItemList.splice(index, 1);
          }
        },
      });
    },
    openFile(index) {
      document.getElementById(index).click();
    },
  },
};
</script>