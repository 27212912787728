<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar flat>
                    <v-card-title>
                        <span class="primary--text">{{$t('addNewStaff')}}</span>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn text fab dark large color="primary" @click="$router.push('/staff')">
                        <v-icon>{{icons.mdiFormatListBulleted}}</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-tabs v-model="tab" show-arrows grow>
                        <v-tab value="1">{{$t('caption.personalInfo')}}</v-tab>
                        <v-tab value="2">{{$t('caption.relatives')}}</v-tab>
                        <v-tab value="3">{{$t('caption.workBackground')}}</v-tab>
                        <v-tab value="4">{{$t('caption.education')}}/បណ្តុះបណ្តាល</v-tab>
                        <v-tab value="5">{{$t('caption.honorableMedals')}}</v-tab>
                        <v-tab value="6">{{$t('caption.technicalSkill')}}</v-tab>
                        <v-tab value="7">{{$t('caption.politicalService')}}</v-tab>
                    </v-tabs>
                    <v-form ref="form" lazy-validation>
                        <v-window v-model="tab">
                            <v-window-item key="1">
                                <!-- New Format -->
                                <v-row class="mt-5">
                                    <v-col cols="12" sm="4">
                                        <v-card>
                                            <v-card-title class="justify-center">{{$t('caption.socialMedia')}}</v-card-title>
                                            <v-card-text class="align-start">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field outlined dense label="Facebook Acc" v-model="staff.facebook" hide-details>

                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field outlined dense label="TikTok Acc" v-model="staff.tiktok" hide-details>

                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field outlined dense :label="$t('text.telegram')" v-model="staff.telegram" hide-details>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field outlined dense :label="$t('text.phoneNumber')" v-model="staff.phone" hide-details>
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-card>
                                            <v-card-title class="justify-center">ព័ត៌មានអត្តសញ្ញាណជាតិ</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="staff.nationalID" outlined dense :label="$t('text.nationalCard')" hide-details>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="staff.nationalIDIssuingDate" outlined dense :label="$t('text.nationalIDIssuedDate')" type="date" hint="month / day / year" hide-details locale="en">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="staff.nationalIDExpiryDate" outlined dense :label="$t('text.nationalIDExpiryDate')" type="date" hint="month / day / year" hide-details>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-btn color="primary" @click="$refs.refInputNationalId.click()" small rounded>
                                                            <v-icon small>{{icons.mdiAttachment}}</v-icon>
                                                        </v-btn>
                                                        (Attach national ID)
                                                        <input type="file" accept=".jpeg,.png,.jpg" :hidden="true" ref="refInputNationalId">
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" class="text-center">
                                                        <v-avatar rounded size="200">
                                                            <img v-if="url" :src="url" />
                                                        </v-avatar>
                                                        <br /> រូបថត 4x6
                                                    </v-col>
                                                    <v-col cols="12" class="text-center">
                                                        <v-btn color="primary" @click="$refs.refInputPhoto.click()" class="me-5" small rounded>
                                                            <v-icon small>{{icons.mdiAttachment}}</v-icon>
                                                        </v-btn>
                                                        <input type="file" @change="onFileChange" accept=".jpeg,.png,.jpg" :hidden="true" ref="refInputPhoto">
                                                        <v-btn color="error" outlined small rounded @click="url='/images/covers/jpg.png'">
                                                            <v-icon small>{{icons.mdiDelete}}</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <!-- End New Format -->
                                <v-row>
                                    <v-col cols="12">
                                        <v-card>
                                            <v-card-title>{{$t('caption.initialInfo')}}</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field outlined dense :label="$t('text.staffNameKh')" v-model="staff.nameKh" hide-details :rules="rule.requiredField()" required>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field outlined dense :label="$t('text.staffNameEn')" v-model="staff.nameEn" hide-details :rules="rule.requiredField()" required>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-radio-group row v-model="staff.gender" class="mt-0 ml-2">
                                                            <v-radio :label="$t('text.male')" :value=0></v-radio>
                                                            <v-radio :label="$t('text.female')" :value=1></v-radio>
                                                        </v-radio-group>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field v-model="staff.dob" :label="$t('text.dateOfBirth')" outlined dense type="date" hint="month / day / year" hide-details></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field outlined dense :label="$t('text.placeOfBirth')" v-model="staff.pob" hide-details>

                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-select v-model="staff.maritalStatus" outlined :label="$t('text.maritalStatus')" :items="maritalStatusList" item-value="id" dense hide-details>
                                                            <template slot="selection" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field outlined dense :label="$t('text.currentAddress')" v-model="staff.address" hide-details>

                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card>
                                            <v-card-title>{{$t('caption.workingInfo')}}</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" md="4">
                                                        <v-select v-model="staff.department.id" outlined :label="$t('select.department')" :items="departmentList" item-value="id" dense :rules="rule.requiredField()" @change="changeDepartment($event)" hide-details>
                                                            <template slot="selection" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <v-text-field v-model="staff.staffCardNumber " outlined dense :label="$t('text.staffIdNumber')" hide-details>
                                                        </v-text-field>

                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field v-model="staff.cardNumber" outlined dense :label="$t('text.idNumber')" hide-details>
                                                        </v-text-field>

                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-btn color="primary" @click="$refs.refInputNominationLetter.click()" outlined small rounded>
                                                            <v-icon small>{{icons.mdiAttachment}}</v-icon>
                                                        </v-btn>
                                                        {{$t('message.attachNominationLetter')}}
                                                        <input type="file" accept=".jpeg,.png,.jpg" :hidden="true" ref="refInputNominationLetter">
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" sm="4" v-if="showPosition">
                                                        <v-select v-model="staff.position.id" outlined :label="$t('select.position')" :items="positionList" item-value="id" dense :rules="rule.requiredField()" required hide-details>
                                                            <template slot="selection" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="4" v-if="showProvince">
                                                        <v-select v-model="province.id" outlined :label="$t('select.province')" item-value="id" :items="provinceList" :rules="rule.requiredField()" required hide-details dense @change="changeProvince($event)">
                                                            <template slot="selection" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="5" v-if="showCourt">
                                                        <v-select v-model="staff.court.id" outlined :label="$t('select.court')" item-value="id" :items="courtList" dense :rules="rule.requiredField()" hide-details>
                                                            <template slot="selection" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                <span v-if="$t('lang') == 'KH'">
                                                                    {{data.item.nameKh}}
                                                                </span>
                                                                <span v-else>{{data.item.nameEn}}</span>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field v-model="staff.startDate" :label="$t('text.startWorkingDate')" outlined dense type="date" hint="month / day / year" hide-details></v-text-field>
                                                    </v-col>
                                                </v-row>

                                            </v-card-text>

                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-window-item>
                            <!-- Relatives -->
                            <v-window-item key="2">
                                <add-relative-section v-bind:data="relatives" v-on:updateRelativesList="updateRelativesList($event)"></add-relative-section>
                            </v-window-item>
                            <!-- End relatives -->
                            <!-- Work background -->
                            <v-window-item key="3">
                                <add-work-section v-bind:data="work" v-on:updateWorkList="updateWorkList($event)"></add-work-section>
                            </v-window-item>
                            <!-- end work background -->
                            <!-- Education -->
                            <v-window-item key="4">
                                <add-education-section v-bind:data="education" v-on:updateEducationList="updateEducationList($event)"></add-education-section>
                                <br />
                                <v-divider></v-divider>
                                <add-training-section v-bind:data="training" v-on:updateTrainingList="updateTrainingList($event)"></add-training-section>
                            </v-window-item>
                            <!-- End education -->
                            <!-- Medal Secon -->
                            <v-window-item key="5">
                                <add-medal-section v-bind:data="medal" v-on:updateMedalList="updateMedalList($event)"></add-medal-section>
                            </v-window-item>
                            <!-- End Medal -->
                            <!-- Technical Skills -->
                            <v-window-item key="6">
                                <add-technical-skill-section v-bind:data="skill" v-on:updateSkillList="updateSkillList($event)"></add-technical-skill-section>
                                <add-computer-skill-section v-bind:data="computer" v-on:updateComputerList="updateComputerList($event)"></add-computer-skill-section>
                            </v-window-item>
                            <!-- End Skills -->

                            <!-- Political Service -->
                            <v-window-item key="7">
                                <add-political-history-section v-bind:data="politicalService" v-on:updatePoliticalServiceList="updatePoliticalServiceList($event)"></add-political-history-section>
                            </v-window-item>
                        </v-window>
                        <br />
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-btn class="btn primary" @click="create">
                                    <v-icon dark left>{{icons.mdiContentSave}}</v-icon>
                                    {{$t('button.save')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import {
  mdiCalendarAlert,
  mdiContentSave,
  mdiAttachment,
  mdiDelete,
  mdiFormatListBulleted
} from '@mdi/js'
import router from '@/router'
import AddTrainingSection from './AddTrainingSection.vue'
import AddEducationSection from './AddEducationSection.vue'
import AddRelativeSection from './AddRelativeSection.vue'
import AddWorkSection from './AddWorkSection.vue'
import AddPoliticalHistorySection from './AddPoliticalHistorySection.vue'
import AddTechnicalSkillSection from './AddTechnicalSkillSection.vue'
import AddComputerSkillSection from './AddComputerSkillSection'
import AddMedalSection from './AddMedalSection'
import SystemService from '@/services/service.system'
import StaffService from '@/services/service.staff'
import StaffDetailService from '@/services/service.staffDetail'
import Rule from '@/plugins/rules.js'
import moment from 'moment'

export default {
  components: {
    AddTrainingSection,
    AddEducationSection,
    AddRelativeSection,
    AddWorkSection,
    AddPoliticalHistorySection,
    AddTechnicalSkillSection,
    AddComputerSkillSection,
    AddMedalSection
  },
  name: 'add-staff',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiContentSave,
        mdiAttachment,
        mdiDelete,
        mdiFormatListBulleted
      },
      tab: null,
      file: null,
      staff: { court: {}, position: {}, department: {} },
      staffDetail: { work: [], training: [] },
      work: [],
      training: [],
      education: [],
      relatives: [],
      politicalService: [],
      skill: [],
      computer: [],
      medal: [],
      // dobMenu: false,
      maritalStatusList: [],
      departmentList: [],
      positionList: [],
      provinceList: [],
      courtList: [],
      province: {},
      rule: [],
      trainingInserted: false,
      workInserted: false,
      relativesInserted: false,
      educationInserted: false,
      politicalServiceInserted: false,
      languageSkillInserted: false,
      computerSkillInserted: false,
      medalHistoryInserted: false,
      showCourt: false,
      showProvince: false,
      showPosition: false,
      formData: new FormData(),
      url: '/images/covers/jpg.png',
      mydate: ''
    }
  },
  async created() {
    this.rule = Rule

    if (this.$route.params.action === 'edit') {
      this.staff = this.$route.params.data

      if (this.staff.court != null) {
        await SystemService.getCourtById(this.staff.court.id).then(response => {
          this.province = response.data.province
        })

        await SystemService.getCourtByProvinceId(this.province.id).then(
          response => {
            this.courtList = response.data
          }
        )

        this.showProvince = true
        this.showCourt = true
      } else {
        this.showCourt = false
      }

      this.staff.dob =
        this.staff.dob != null ? this.formatDate(this.staff.dob) : ''

      if (this.staff.department == null) {
        this.staff.department = {}
      } else {
        SystemService.getPositionByDepartmentId(this.staff.department.id).then(
          response => {
            this.positionList = response.data
          }
        )
      }
      this.showPosition = true

      if (
        this.staff.profilePicture != null &&
        this.staff.profilePicture.length > 0
      ) {
        this.getPhotoByStaffId(this.staff.id)
      }
    }

    SystemService.getMaritalStatusAll().then(response => {
      this.maritalStatusList = response.data
    })

    SystemService.getDepartmentAll().then(response => {
      this.departmentList = response.data
    })

    SystemService.getProvinceAll().then(response => {
      this.provinceList = response.data
    })
  },

  methods: {
    async getPhotoByStaffId(id) {
      await StaffService.getPictureByStaffId(id).then(response => {
        this.url = URL.createObjectURL(response.data)
      })
    },
    async create() {
      if (!this.$refs.form.validate()) return

      if (this.formData.has('file')) {
        await StaffService.uploadProfile(this.formData).then(
          response => {
            if (response.data) {
              this.staff.profilePicture = response.data
              this.reset()
            }
          },
          error => {
            console.log('No photo selected')
          }
        )
      }

      await StaffService.insert(this.staff).then(
        response => {
          if (response.data) {
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text:
                this.$route.params.action == 'edit'
                  ? 'Staff has been updated'
                  : 'Staff has been created'
            })

            if (this.work.length > 0) {
              this.work = this.work.map(v => ({ ...v, staff: response.data }))

              StaffDetailService.insertWorkBatch(this.work).then(response => {
                this.workInserted = true
              })
            }

            if (this.education.length > 0) {
              this.education = this.education.map(v => ({
                ...v,
                staff: response.data
              }))

              StaffDetailService.insertEducationBatch(this.education).then(
                response => {
                  this.educationInserted = true
                }
              )
            }

            if (this.training.length > 0) {
              this.training = this.training.map(v => ({
                ...v,
                staff: response.data
              }))

              StaffDetailService.insertTrainingBatch(this.training).then(
                response => {
                  this.trainingInserted = true
                }
              )
            }

            if (this.relatives.length > 0) {
              this.relatives = this.relatives.map(v => ({
                ...v,
                staff: response.data
              }))

              StaffDetailService.insertRelativesBatch(this.relatives).then(
                response => {
                  this.relativesInserted = true
                }
              )
            }

            if (this.politicalService.length > 0) {
              this.politicalService = this.politicalService.map(v => ({
                ...v,
                staffId: response.data.id
              }))

              StaffDetailService.insertPoliticalServiceBatch(
                this.politicalService
              ).then(response => {
                this.politicalServiceInserted = true
              })
            }
            if (this.skill.length > 0) {
              this.skill = this.skill.map(v => ({
                ...v,
                staffId: response.data.id
              }))

              StaffDetailService.insertLanguageSkillBatch(this.skill).then(
                response => {
                  this.languageSkillInserted = true
                }
              )
            }

            if (this.computer.length > 0) {
              this.computer = this.computer.map(v => ({
                ...v,
                staffId: response.data.id
              }))

              StaffDetailService.insertComputerSkillBatch(this.computer).then(
                response => {
                  this.computerSkillInserted = true
                }
              )
            }

            if (this.medal.length > 0) {
              this.medal = this.medal.map(v => ({
                ...v,
                staffId: response.data.id
              }))
              StaffDetailService.insertMedalHistoryBatch(this.medal).then(
                response => {
                  this.medalHistoryInserted = true
                }
              )
            }

            router.push({ name: 'staff' })
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: error.response.data
          })
        }
      )

      /* ---- */
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    onFileChange: function(event) {
      const file = event.target.files[0]
      this.formData = new FormData()
      this.formData.append('file', file)
      this.url = URL.createObjectURL(file)
    },
    reset() {
      //this.$refs[`form`].reset();
      this.url = '/images/covers/jpg.png'
      this.formData = new FormData()
    },
    changeDepartment: function(event) {
      if (event == 9 || event == 5 || event == 6) {
        this.showProvince = false
        this.showCourt = false
        this.province = {}
      } else {
        this.showProvince = true
      }

      this.showPosition = true
      this.staff.position = {}
      SystemService.getPositionByDepartmentId(event).then(response => {
        this.positionList = response.data
      })
    },
    changeProvince: function(provinceId) {
      this.showCourt = true
      this.staff.court = {}

      SystemService.getCourtByProvinceId(provinceId).then(response => {
        this.courtList = response.data
      })
    },
    createTraining(trainingList, staffId) {
      StaffDetailService.insertTrainingBatch
    },
    updateEducationList(newList) {
      this.education = newList
    },
    updateTrainingList(newList) {
      this.training = newList
    },
    updateRelativesList(newList) {
      this.relatives = newList
    },
    updatePoliticalServiceList(newList) {
      this.politicalService = newList
    },
    updateWorkList(newList) {
      this.work = newList
    },
    updateSkillList(newList) {
      this.skill = newList
    },
    updateComputerList(newList) {
      this.computer = newList
    },
    updateMedalList(newList) {
      this.medal = newList
    }
  }
}
</script>
