<template>
<div>
        <v-card-title><span class="primary--text">{{$t('caption.computerSkill')}} </span></v-card-title>
        <v-card-text>
            <div v-for="(item, index) in computerItemList" :key="item.id">
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-select
                            v-model="item.skillId"
                            outlined                                               
                            :label="$t('select.selectSkill')"
                            item-value="id"
                            :items="computerList"
                            :rules="rule.requiredField()"
                            hide-details
                            dense
                        >
                            <template slot="selection" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                {{data.item.skillNameKh}}
                                </span>
                                <span v-else>{{data.item.skillNameEn}}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                {{data.item.skillNameKh}}
                                </span>
                                <span v-else>{{data.item.skillNameEn}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-select
                            v-model="item.grade"
                            outlined                                               
                            :label="$t('select.grade')"
                            item-value="value"
                            :items="gradeList"
                            hide-details
                            dense
                        >
                            <template slot="selection" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <v-icon large color="primary" @click="removeComputerItem(index)">{{icons.mdiDelete}}</v-icon>
                    </v-col>
                </v-row>
            </div>
            <v-row class="mt-5 justify-center">
                <v-col cols="4" align="center">
                    <v-btn 
                        class="btn primary" 
                        @click="addComputerItem($event)"
                        small
                        rounded
                    ><v-icon small>{{icons.mdiPlus}}</v-icon> Computer Skill</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
</div>
</template>

<script>
import { mdiCalendarAlert, mdiPlus, mdiMinus, mdiDelete } from '@mdi/js';
import SystemService from '@/services/service.system';
import StaffDetailService from '@/services/service.staffDetail';
import Rule from '@/plugins/rules.js';

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiMinus,
        mdiDelete,
      },
      rule: [],
      skill: [],

      gradeList: [
        { value: 'A', text: 'ពូកែ' },
        { value: 'B', text: 'បង្គួរ' },
        { value: 'C', text: 'មធ្យម' },
        { value: 'D', text: 'ខ្សោយ' },
      ],
      computerList: [
        {
          computerId: 10,
          computer: 'វាយអក្សរខ្មែរ',
        },
        {
          computerId: 11,
          computer: 'វាយអក្សរអង់គ្លេស',
        },
        {
          computerId: 12,
          computer: 'Microsoft Word',
        },
        {
          computerId: 13,
          computer: 'Microsoft Excel',
        },
        {
          computerId: 14,
          computer: 'Database',
        },
      ],
      computerItemList: [],
    };
  },
  created() {
    this.rule = Rule;

    SystemService.getComputerSkills().then(response => {
      this.computerList = response.data;
    });

    if (this.$route.params.action === 'edit') {
      this.staff = this.$route.params.data;

      StaffDetailService.getComputerSkillByStaffId(this.staff.id).then(response => {
        this.computerItemList = response.data;
      });
    }
  },
  watch: {
    computerItemList: function(val) {
      this.$emit('updateComputerList', val);
    },
  },
  methods: {
    addComputerItem() {
      this.computerItemList.push({});
    },
    removeComputerItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            this.computerItemList.splice(index, 1);
          }
        },
      });
    },
    uppercase(event) {
      //  alert(JSON.stringify(event.target.value));
      return event.target.value.toUpperCase();
      //this.code = this.code.toUpperCase();
    },
  },
};
</script>
