<template>
<div>
    <div v-for="(item, index) in politicalHistoryItemList" :key="item.id">
    <v-row class="mt-5">
        <v-col cols="12" md="4">
            <v-text-field
                v-model="item.partyName"
                outlined
                hide-details
                :label="$t('text.partyName')"
                :rules="rule.requiredField()"
                dense
            >

            </v-text-field>
        </v-col>
        <v-col cols="12" md="5">
            <v-text-field
                v-model="item.address"
                outlined
                hide-details
                :label="$t('text.workingPlace')"
                dense
            >

            </v-text-field>
            
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field
                v-model="item.country"
                outlined
                hide-details
                :label="$t('text.country')"
                dense
            >

            </v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="3">
            <v-text-field
                v-model="item.position"
                outlined
                hide-details
                :label="$t('text.role')"
                dense
            >

            </v-text-field>
        </v-col>
        <v-col cols="6" md="3">
            <v-text-field
                v-model="item.cardNumber"
                outlined
                hide-details
                :label="$t('text.idNumber')"
                dense
            >

            </v-text-field>
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                v-model="item.startDate"
                :label="$t('text.startDate')"
                outlined
                dense
                type="date"
                hide-details
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                v-model="item.endDate"
                :label="$t('text.endDate')"
                outlined
                dense
                type="date"
                hide-details
            ></v-text-field>
        </v-col>
        <v-col cols="6" sm="2">
            <v-btn
                color="primary"
                @click="openFile(index)"
                small
                rounded
            >
                <v-icon small>{{icons.mdiAttachment}}</v-icon>
            </v-btn>
                
            <input type="file"
                accept=".jpeg,.png,.jpg"                       
                :hidden="true"
                :id="index"
            >
            <v-icon class="mr-5" large color="primary" @click="removeItem(index)">{{icons.mdiDelete}}</v-icon>
        </v-col>
        
    </v-row>
    <!--
    <v-row class="justify-end">
        <v-spacer></v-spacer>
        <v-col cols="4" class="text-right">
            <v-btn 
                class="btn primary" 
                v-if="trainingItemList.length === index+1"
                @click="addItem($event)"
                small
            ><v-icon small>{{icons.mdiPlus}}</v-icon></v-btn>
        </v-col>
    </v-row>
    -->
    </div>
    <v-row class="mt-5 justify-center">               
        <v-col cols="4" align="center">
            <v-btn 
                class="btn primary" 
                @click="addItem($event)"
                small
                rounded
            ><v-icon small>{{icons.mdiPlus}}</v-icon></v-btn>
        </v-col>
    </v-row>
</div>
</template>

<script>
import { mdiCalendarAlert, mdiPlus, mdiAttachment, mdiDelete } from '@mdi/js';
import StaffDetailService from '@/services/service.staffDetail';
import Rule from '@/plugins/rules.js';

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiAttachment,
        mdiDelete,
      },
      rule: [],
      politicalHistoryItemList: [],
    };
  },
  created() {
    this.rule = Rule;

    if (this.$route.params.action === 'edit') {
      this.staff = this.$route.params.data;

      StaffDetailService.getPoliticalServiceByStaffId(this.staff.id).then(response => {
        this.politicalHistoryItemList = response.data;
      });
    }
  },
  watch: {
    politicalHistoryItemList: function(val) {
      this.$emit('updatePoliticalServiceList', val);
    },
  },
  methods: {
    addItem() {
      this.politicalHistoryItemList.push({});
    },
    removeItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            this.politicalHistoryItemList.splice(index, 1);
          }
        },
      });
    },
    openFile(index) {
      document.getElementById(index).click();
    },
  },
};
</script>
